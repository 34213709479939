<template>
	<Loading></Loading>
</template>

<script>
	
	import Loading from "../Layout/Loading";
	export default{
		name: 'Main'
		,
		components: {Loading},
		props: ['Axios']
		,data: function(){
			return {
				ecosystem: [
					{
						text: 'vuetify-loader',
						href: 'https://github.com/vuetifyjs/vuetify-loader',
					},
					{
						text: 'github',
						href: 'https://github.com/vuetifyjs/vuetify',
					},
					{
						text: 'awesome-vuetify',
						href: 'https://github.com/vuetifyjs/awesome-vuetify',
					},
				],
				importantLinks: [
					{
						text: 'mid icon',
						href: 'https://pictogrammers.github.io/@mdi/font/5.4.55/',
					},
				],
				whatsNext: [
					{
						text: 'Axios',
						href: 'https://github.com/axios/axios',
					},
					{
						text: 'Router',
						href: 'https://router.vuejs.org/kr/installation.html',
					},
					{
						text: 'Vuetify',
						href: 'https://vuetifyjs.com/en/getting-started/installation/',
					},
					{
						text: 'DaumPost',
						href: 'https://www.npmjs.com/package/vue-daum-postcode',
					},
				],
			}	
		}
		
		,created: function(){
			this.$router.push({ name: 'Index'})
			//this.$bus.$emit('notify', { type: 'success', message: 'base template'})
		}
	}
	
</script>